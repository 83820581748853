<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<!-- <style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }
</style> -->
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import printCardHeader from "./printCard-header";
  import {
    addZkz,
    editZkz,
    getZkzInfo
  } from "@/api/printCard/printCard.js"
  import {
    getExamEasy,
  } from "@/api/admin/exam/examRecord.js"

  /**
   * 准考证库导入--基本信息
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      printCardHeader


    },
    data() {
      return {
        title: "准考证库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "准考证库导入",
            active: true
          }
        ],
        years: [],
        examList: [],
        sid: "",
        ksmc: "",
        ksbmbh: "",
        addForm: {
          ksnf: new Date().getFullYear()
        },
        checkForm: [],
        columns: [{
            name: "姓名",
            val: "ksxm"
          },
          {
            name: "证件号码",
            val: "zjhm"
          },
          {
            name: "准考证号",
            val: "zkzh"
          }
        ]
      };
    },
    methods: {
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 6; i++) {
          if (i < 6) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 获取考试
      getExamList() {
        getExamEasy({
          ksnf: this.addForm.ksnf
        }).then(res => {
          if (res.status) {
            this.examList = res.data
          }
        })
      },
      // 添加
      addItem() {
        let fromData = JSON.parse(JSON.stringify(this.addForm))
        // if (!this.addForm.ksbmbh) {
        fromData.ksmc = this.ksmc
        // }
        addZkz(fromData).then(res => {
          if (res.status) {
            this.sid = res.data.sid
            this.ksbmbh = res.data.ksbmbh
            this.success()
          }
        })
      },
      // 修改
      editItem() {
        let fromData = JSON.parse(JSON.stringify(this.addForm))
        // if (!this.addForm.ksbmbh) {
        fromData.ksmc = this.ksmc
        // }
        editZkz({
          ...fromData,
          sid: this.sid
        }).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      getKsmc() {
        let val = this.addForm.ksbmbh

        let obj = this.examList.find(k => {
          return k.ksbmbh === val
        })
        if (obj) {
          this.ksmc = obj.ksmc
        } else {
          this.ksmc = ""
        }
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        window.location.href = "/admin/printCardImportMatch?sid=" + this.sid;
        // this.$refs.header.getUrl('/admin/scoreImportMatch?sid='+this.sid)

      },
      // 获取详情
      getDeatails(sid) {
        getZkzInfo(sid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.ksmc = res.data.ksmc ? res.data.ksmc : ""
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            if (res.data.cxtj) {
              this.checkForm = res.data.cxtj.split(",")
            } else {
              this.checkForm = []
            }
          }
        })

      },
      test() {
        let str = this.checkForm.join(",")
        if (str) {
          this.addForm.cxtj = str
        }

      },
      // 提交时分流
      submit() {
        if (this.sid) {
          this.editItem()
        } else {
          this.addItem()
        }
      }
    },
    mounted() {
      this.getYear()
      this.getExamList()
      if (this.sid) {
        this.getDeatails(this.sid)
      }
    },
    created() {
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <printCardHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 460px;">
          <div class="card-body">
            <div class="outerCotent mt-3 ">
              <div class="msgContent flexList fs-xs">
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">选择年份</div>
                  <div class="msgVal selectShow flexList">
                    <select class="form-control w-30 msgValInput" v-model="addForm.ksnf" @change="getExamList()">
                      <option>年份 </option>
                      <option v-for="(item, i) in years" :value="item.value" :key="i">{{item.value}}</option>
                    </select>

                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">关联报名照片库
                  </div>
                  <div class="msgVal selectShow  flexList w-33">
                    <select class="form-control w-30 msgValInput" v-model="addForm.ksbmbh" @change="getKsmc">
                      <option disabled>请选择考试 </option>
                      <option value="">其他</option>
                      <option v-for="(item, i) in examList" :value="item.ksbmbh" :key="i">{{item.ksmc}}</option>

                    </select>

                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">准考证考试名称
                  </div>
                  <div class="msgVal selectShow  flexList ">
                    <input type="text" name="" id="" maxlength="50" value="" v-model="ksmc" placeholder="请输入考试名称"
                      class="form-control w-30 h30 " />

                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">查询条件</div>
                  <div class="msgVal   flexList ">
                    <el-checkbox-group v-model="checkForm" @change="test">
                      <el-checkbox v-for="(item,index) in columns" :key="index" :value="item.val" :label="item.val"
                        class="mb-0">
                        {{item.name}}
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </div>


                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName"></div>
                  <div class="msgVal flexList">
                    <button class="btn btn-info h35 mr-2 sub-btn" @click="submit">点击进入下一步</button>
                    <a href="javascript:window.location='/admin/printCardList'"><button
                        class="btn btn-secondary h35 mr-2 ">返回列表</button></a>
                  </div>
                </div>
              </div>

            </div>



          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
